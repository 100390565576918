<template>
  <section class="w-100 position-relative">

    <!-- Grid for images -->
    <div class="grid-container mb-2">
      <div class="grid-item" v-for="(image, index) in getImagesContent()" :key="index">
        <img :src="image.preview_url" class="img-cover" @click="redirectPost(image.code, image.network)"/>
        <div class="container-icon-heart">
          <p class="d-flex align-items-center" v-if="image.likes_count">
            <feather-icon icon="HeartIcon" class="mr-05"></feather-icon>
            <span>{{ getFormat(image.likes_count) }}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- Grid analytics underneath images -->
    <div class="grid-analytics mb-2">
      <div v-for="(item, index) in analytics_data" :key="index" class="grid-item box-style-analytics">
        <span class="avenir-medium">{{ item.label }}</span>
        <span class="title-result">{{ item.value }}</span>
      </div>
    </div>

    <!-- Box for categories and extra data -->
    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <p class="f-12 m-0">
          <span class="text-uppercase text-blue text-underline cursor-pointer" @click="redirectToSocialNet(network, data_user.basic.username)">@{{data_user.basic.username }}</span>
          · {{ getFormat(data_user.parent_data.followers) }} <span class="text-uppercase"> {{ $t('campaigns.followers') }}</span>
        </p>
        <b-button variant="outline-secondary-custom-analysis" @click="redirectToSocialNet(network, data_user.basic.username)" class="outline-secondary-custom-analysis">
          <feather-icon icon="ExternalLinkIcon"></feather-icon>
        </b-button>
      </div>

      <h4>{{ data_user.basic.title }}</h4>
      <p class="avenir-medium border-bottom pb-1">{{ data_user.basic.description }}</p>

      <p class="text-uppercase f-12">{{ $t('search_preview.contentCategory') }}</p>

      <div class="grid-categories">
        <div v-for="(category, index) in data_user.parent_data.categories.all_categories.slice(0, 6)" :key="index" class="avenir-medium d-flex flex-column align-items-center text-center">
          <b-img :src="getIconInterest(category)" class="img-grid-categorie" v-if="getIconInterest(category)"></b-img>
          <b-icon :icon="getCategoryIconSearcher(category)" v-else class="icon-b"></b-icon>
          <span class="mt-1 d-block">
            {{ $te(`instagram.${category}`) ? $t(`instagram.${category}`) : category }}
          </span>
        </div>
      </div>
      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid || has_expired">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>

    <!-- Engagement Graph -->
    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ network === 'ig' ? $t('twitter.engagementRate') : $t('search_preview.info_youtube.engagement_30_videos')}}</p>
      <div class="d-flex align-items-center gap-2 mb-1" v-if="data_user.metrics.er.mark !== 'none'">
        <h2 class="m-0">{{ data_user.metrics.er.value.toFixed(2)}}%</h2>
        <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).color}`">
          {{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).text }}
        </b-badge>
      </div>
      <h2 v-else>N/D</h2>
      <p class="avenir-medium" v-if="data_user.metrics.er.mark">
        <span v-if="data_user.metrics.er.mark === 'good' || data_user.metrics.er.mark === 'excellent'">{{ $t('search_preview.engagementbest') }} {{ data_user.metrics.er.prc_better }} %</span>
        {{ $t(`search_preview.engagement_nevel.${data_user.metrics.er.mark}`).long}}
      </p>
      <scale-rate-search v-if="data_user.metrics.er.mark && data_user.metrics.er.mark !== 'none'" :mark="data_user.metrics.er.mark"></scale-rate-search>
    </div>

    <!-- Estimed scoped for Instagram -->
    <div class="box-style-analytics mb-2" v-if="network === 'instagram'">
      <div class="d-flex justify-content-between">
        <p class="text-uppercase f-12">{{ $t('search_preview.estimedReach') }}</p>
  
        <div class="toggle-button-post-history">
          <b-button :class="{'active-button': showing_post_data}" @click="showing_post_data = true">{{ $t('search_preview.post') }}</b-button>
          <b-button :class="{'active-button': !showing_post_data}" @click="showing_post_data = false">{{ $t('instagram.History') }}</b-button>
        </div>
      </div>
  
      <!-- POST DATA -->
      <div class="d-flex mb-1 flex-wrap" v-if="!Array.isArray(data_user.features.blogger_reach) && showing_post_data">
        <h2 class="m-0">{{ getFormat(data_user.features.blogger_reach.data.reach_from) }} - {{ getFormat(data_user.features.blogger_reach.data.reach_to) }}</h2>
        <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.reach_mark}`).text }}</b-badge>
        <p class="avenir-medium d-block w-100 mt-1">
          <span>{{ $t('search_preview.publish_seen') }} {{ data_user.calculated_metrics.prc_post_estimated_reach }} %</span>
          {{ $t(`search_preview.reach_estimed.${data_user.features.blogger_reach.data.reach_mark}`)}}
        </p>
      </div>
  
      <!-- HISTORY DATA -->
      <div class="d-flex mb-1 flex-wrap" v-if="!Array.isArray(data_user.features.blogger_reach) && !showing_post_data">
        <h2 class="m-0">{{ getFormat(data_user.features.blogger_reach.data.stories_reach_from) }} - {{ getFormat(data_user.features.blogger_reach.data.stories_reach_to) }}</h2>
        <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.stories_reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_reach.data.stories_reach_mark}`).text }}</b-badge>
        <p class="avenir-medium d-block w-100 mt-1">
          <span>{{ $t('search_preview.histories_seen') }} {{ data_user.calculated_metrics.prc_story_estimated_reach }} %</span>
          {{ $t(`search_preview.reach_estimed.${data_user.features.blogger_reach.data.reach_mark}`)}}
        </p>
      </div>
  
      <div v-else-if="Array.isArray(data_user.features.blogger_reach)" class="mb-1">
        <h2>N/D</h2>
        <p class="avenir-medium">{{ $t(`search_preview.reach_estimed.none`)}}</p>
      </div>
  
      <div class="grid-inner-box">
        <div class="inner-box">
          <p class="avenir-medium">{{ $t('search_preview.avgLikes') }}</p>
          <h4>{{ data_user.metrics.likes_avg.value ? getFormat(data_user.metrics.likes_avg.value) : 0 }}</h4>
        </div>
        
        <div class="inner-box">
          <p class="avenir-medium">{{ $t('preview.avg_comments') }}</p>
          <h4>{{ data_user.metrics.comments_avg.value ? getFormat(data_user.metrics.comments_avg.value) : 0 }}</h4>
        </div>
  
        <div class="inner-box">
          <p class="avenir-medium">{{ $t('search.averageViewsTiktok')}}</p>
          <h4>{{ data_user.metrics.short_video_views_avg.value ? getFormat(data_user.metrics.short_video_views_avg.value) : 0 }}</h4>
        </div>
  
        <div class="inner-box">
          <p class="avenir-medium">{{ $t('search_preview.avgPost') }}</p>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="m-0">{{ data_user.metrics.media_per_week.value ? getFormat(data_user.metrics.media_per_week.value) : 0 }}</h4>
            <b-badge :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.media_per_week.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.media_per_week.mark}`).text }}</b-badge>
          </div>
        </div>            
      </div>
    </div>

    <!-- AQS -->
    <div class="box-style-analytics mb-2" v-if="data_user.features.aqs">
      <p class="text-uppercase f-12">{{ $t('tiktok.aqs_title') }}</p>
      <div class="d-flex mb-1" v-if="data_user.features.aqs.data.mark !== 'none'">
        <h2 class="m-0">{{ data_user.features.aqs.data.value }} / 100 </h2>
        <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.aqs.data.mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.aqs.data.mark}`).text }}</b-badge>
      </div>
      <p class="avenir-medium" v-if="data_user.features.aqs.data.mark === 'good' || data_user.features.aqs.data.mark === 'excellent' || data_user.features.aqs.data.mark === 'very_good'">
        <span>{{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`).first }} {{ data_user.features.aqs.data.prc_better }}%</span>
        {{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`).second}}.
      </p>
      <p v-else class="avenir-medium">{{ $t(`search_preview.aqs.${data_user.features.aqs.data.mark}`) }}</p>

      <scale-rate-search v-if="data_user.features.aqs.data.mark && data_user.features.aqs.data.mark !== 'none'" :mark="data_user.features.aqs.data.mark === 'very_good' ? 'high' : data_user.features.aqs.data.mark" :extra_key="'1'"></scale-rate-search>

    </div>

    <!-- Account growth rate -->
    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t('search_preview.growthAccount') }}</p>
      <div class="mb-1" v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark !== 'none'">
        <div class="d-flex mb-1">
          <h2 class="m-0">{{ data_user.metrics.subscribers_growth_prc.performance['365d'].value.toFixed(2)}}%</h2>
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`).text }}</b-badge>
        </div>
        <p class="avenir-medium">
          <span v-if="data_user.metrics.subscribers_growth_prc.performance['365d'].mark === 'average'">{{ $t(`search_preview.suscribers_growth.average`) }}</span>
          <span v-else>{{ $t(`search_preview.suscribers_growth.${data_user.metrics.subscribers_growth_prc.performance['365d'].mark}`) }} {{ data_user.metrics.subscribers_growth_prc.performance['365d'].similar.toFixed(2)  }}%</span>
        </p>
      </div>
      <div v-else>
        <h2>N/D</h2>
        <p class="avenir-medium">{{  $t(`search_preview.suscribers_growth.none`) }}</p>
      </div>
    
      <div class="mt-2" v-if="subscribers_growth_prc_graph">
        <profile-crecimiento :audienceData="{followers_chart: this.subscribers_growth_prc_graph, following_chart: []}" :show_following="false"></profile-crecimiento>
      </div>
    </div>

    <!-- Prices and evaluation -->
    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between">
        <p class="text-uppercase f-12">{{ $t('search_preview.priceRendiment') }}</p>

        <div class="toggle-button-post-history" v-if="data_user.features.blogger_prices.data && network === 'instagram'">
          <b-button :class="{'active-button': showing_post_data_prices}" @click="showing_post_data_prices = true">{{ $t('search_preview.post') }}</b-button>
          <b-button :class="{'active-button': !showing_post_data_prices}" @click="showing_post_data_prices = false">{{ $t('instagram.History') }}</b-button>
        </div>
      </div>

      <!-- POST DATA -->
      <div class="d-flex mb-1 flex-wrap" v-if="data_user.features.blogger_prices.data && showing_post_data_prices">
        <h2 class="m-0">${{ getFormat(data_user.features.blogger_prices.data.post_price_from) }} - ${{ getFormat(data_user.features.blogger_prices.data.post_price_to) }}</h2>
        <!-- <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.reach_mark}`).text }}</b-badge> -->
        <p class="avenir-medium d-block w-100 mt-1">
          <span>{{ $t('search_preview.price.a') }} ${{ cpe_prices }} </span>
          {{ network === 'instagram' ? $t('search_preview.price.cpe') : $t('search_preview.info_youtube.blogger_prices')}}.
        </p>
      </div>

      <!-- HISTORY DATA -->
      <div class="d-flex mb-1 flex-wrap" v-if="data_user.features.blogger_prices.data && !showing_post_data_prices && network === 'instagram'">
        <h2 class="m-0">${{ getFormat(data_user.features.blogger_prices.data.stories_price_from) }} - ${{ getFormat(data_user.features.blogger_prices.data.stories_price_to) }}</h2>
        <!-- <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.stories_reach_mark}`).color}`">{{ $t(`search_preview.engagement_nevel.${data_user.features.blogger_prices.data.stories_reach_mark}`).text }}</b-badge> -->
        <p class="avenir-medium d-block w-100 mt-1">
          <span>{{ $t('search_preview.price.a') }} ${{ data_user.features.blogger_prices.data.cpe.toFixed(2) }}</span>
          {{ $t('search_preview.price.cpe')}}.
        </p>
      </div>

      <div v-else-if="!data_user.features.blogger_prices.data" class="mb-1">
        <h2>N/D</h2>
        <p class="avenir-medium">{{ $t(`search_preview.price.none`)}}</p>
      </div>

      <!-- Factors risk with impact in cost -->
      <div v-if="!Array.isArray(data_user.calculated_metrics.price_affected_by)">
        <p class="text-uppercase f-12 border-top pt-1">{{ $t('search_preview.factorsAffect') }}:</p>
        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.country">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.country.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p>
            <span>{{ $t(`search_preview.price.country.creator_from`)}} </span>
            <span>{{ data_user.calculated_metrics.price_affected_by.country.data.country }}</span>
            <span>{{ $t(`search_preview.price.country.${data_user.calculated_metrics.price_affected_by.country.affect}`)}} </span>
          </p>
        </div>

        <div class="avenir-medium d-flex align-items-start justify-content-between mb-1" v-if="data_user.calculated_metrics.price_affected_by.quality_audience">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.quality_audience.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p><span>{{ $t(`search_preview.price.audienceAuthenticity.${data_user.calculated_metrics.price_affected_by.quality_audience.affect}`)}} </span></p>
        </div>

        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.er">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.er.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p>
            <span v-if="data_user.calculated_metrics.price_affected_by.er.affect === 'positive'">{{ $t(`search_preview.price.er.positive_first`)}} {{ data_user.calculated_metrics.price_affected_by.er.data.prc_better }}% {{ $t(`search_preview.price.er.positive_second`) }}</span>
            <span v-else>{{ $t(`search_preview.price.er.negative`) }}</span>
          </p>
        </div>

        <div class="avenir-medium d-flex align-items-start" v-if="data_user.calculated_metrics.price_affected_by.ad_post_frequency">
          <feather-icon :icon="data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect === 'positive' ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="icon-arrows-prices"></feather-icon>
          <p><span>{{ $t(`search_preview.price.advertisingPost.${data_user.calculated_metrics.price_affected_by.ad_post_frequency.affect}`)}} </span></p>
        </div>
      </div>
    </div>

    <!-- Brand mentions for Instagram-->
    <div class="box-style-analytics mb-2" v-if="network === 'instagram'">
      <p class="text-uppercase f-12">{{ $t(`search_preview.mentions180`)}}</p>
      <div class="mb-1" v-if="mentions_user">
        <div class="d-flex mb-1">
          <h2 class="m-0">{{ separatebycomma(mentions_user.summary.ad.posts_count) }}</h2>
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).color}`">
            {{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).mark }}
          </b-badge>
        </div>
        <p class="avenir-medium">
          <span>{{ $t(`search_preview.${mentionsCase(mentions_user.summary.ad.posts_count)}`).long }}</span>
        </p>
      </div>
      <div v-else>
        <h2>N/D</h2>
        <p class="avenir-medium">{{  $t(`search_preview.mentions_text_mark.none`) }}</p>
      </div>

      <b-avatar-group size="44px" class="mt-2">
        <b-avatar v-for="(avatar, index) in mentions_avatares.slice(0, 9)" :key="index" :src="avatar"></b-avatar>
        <b-avatar v-if="mentions_avatares.length > 9" :text="`+${mentions_avatares.length - 9}`" class="backgroud-gray-light-avatar"></b-avatar>
      </b-avatar-group>
    </div>

    <!-- Brand mentions for Youtube-->
    <div class="box-style-analytics mb-2" v-if="network === 'youtube'">
      <p class="text-uppercase f-12">{{ $t(`search_preview.mentions90`)}}</p>
      <div class="mb-1" v-if="mentions_yt">
        <div class="d-flex mb-1">
          <h2 class="m-0">{{ separatebycomma(mentions_yt.count) }}</h2>
          <b-badge class="badge-nivel" :style="`background-color: ${$t(`search_preview.${mentionsCase(mentions_yt.count)}`).color}`">
            {{ $t(`search_preview.${mentionsCase(mentions_yt.count)}`).mark }}
          </b-badge>
        </div>
        <p class="avenir-medium">
          <span>{{ $t(`search_preview.${mentionsCase(mentions_yt.count)}`).long }}</span>
        </p>
      </div>
      <div v-else>
        <h2>N/D</h2>
        <p class="avenir-medium">{{  $t(`search_preview.mentions_text_mark.none`) }}</p>
      </div>

      <div class="grid-inner-box" v-if="mentions_yt && mentions_yt.data.length > 0">
        <div class="inner-box" v-for="item in mentions_yt.data" :key="item.id">
          <b-avatar :src="item.features.merged_domains.data[0].basic.logo_urls[0]" class="mb-05" :text="item.basic.title.slice(0, 2)"></b-avatar>
          <h5 class="">{{ item.basic.title }}</h5>
          <p class="avenir-medium mt-1 mb-0">{{ getFormat(item.metrics.views_total.value) }} {{ $t('search_preview.info_youtube.totalviews') }}</p>
        </div>
      </div>
    </div>

    <!-- More info report -->
    <div class="box-style-analytics mb-2">
      <p class="text-uppercase f-12">{{ $t(`search_preview.moreInfoReport`)}}...</p>

      <div class="grid-more-info">
        <div class="avenir-medium d-flex align-items-start" v-for="p in more_info" :key="p">
          <feather-icon icon="LockIcon" class="icon-arrows-prices"></feather-icon>
          <p>{{ p }}</p>
        </div>
      </div>

      <b-button class="blue-button w-100 mt-2" variant="blue-button" @click.once="$emit('get_profile_url')">
        <span v-if="!is_paid || has_expired">
          {{ $t('search_preview.unlockReport') }}
          <b-img :src="require(`@/assets/images/icons/moneda.svg`)" class="icon-coin"></b-img> 1
        </span>
        <span v-else>{{ $t('search_preview.seeFullReport') }}</span>
      </b-button>
    </div>
    
  </section>

</template>
  
<script>
import { getFormat, separatebycomma } from "@/libs/utils/formats";
import { getCategoryIconSearcher, getIconInterest } from "@/libs/utils/icons";
import { redirectToSocialNet } from '@/libs/utils/urls';
import { 
  BButton,
  BImg,
  BBadge,
  BAvatar,
  BAvatarGroup
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BImg,
    BBadge,
    BAvatar,
    BAvatarGroup,
    scaleRateSearch: () => import('@/views/components/graph/scaleRateSearch.vue'),
    ProfileCrecimiento: () => import('@/views/pages/profile/ProfileCrecimiento.vue'),
  },
  props: ['data_user', 'network', 'is_paid', 'has_expired', 'subscribers_growth_prc_graph', 'mentions_avatares', 'mentions_user'],
  data() {
    return {
      getFormat,
      redirectToSocialNet,
      getIconInterest,
      getCategoryIconSearcher,
      separatebycomma,
      showing_post_data: true,
      showing_post_data_prices: true,
    };
  },
  computed: {
    analytics_data() {
      if (this.network === 'instagram') {
        return [
          {
            label: 'ER',
            value: this.data_user.metrics.er?.value ? `${this.data_user.metrics.er.value.toFixed(2)} %` : 'N/A'
          },
          {
            label: this.$t('search_preview.avgLikes'),
            value: this.data_user.metrics.likes_avg?.value ? getFormat(this.data_user.metrics.likes_avg.value) : 'N/A'
          },
          {
            label: this.$t('search_preview.reach'),
            value: this.data_user.features.blogger_reach?.data?.reach ? getFormat(this.data_user.features.blogger_reach.data.reach) : 'N/A'
          },
          {
            label: this.$t('search_preview.estimedPrice'),
            value: this.data_user.features.blogger_prices?.data?.post_price ? `$${getFormat(this.data_user.features.blogger_prices.data.post_price)}` : 'N/A'
          }
        ];
      } else {
        return [
          {
            label: this.$t('search_preview.info_youtube.er_videos'),
            value: this.data_user.metrics.er?.value ? `${this.data_user.metrics.er.value.toFixed(2)} %` : 'N/A'
          },
          {
            label: this.$t('search_preview.info_youtube.er_shorts'),
            value: this.data_user.metrics.er?.value_short ? `${this.data_user.metrics.er.value_short.toFixed(2)} %` : 'N/A'
          },
          {
            label: this.$t('search_preview.info_youtube.price_integration'),
            value: this.data_user.features.blogger_prices?.data?.post_price ? `$${getFormat(this.data_user.features.blogger_prices.data.post_price)}` : 'N/A'
          },
          {
            label: this.$t('search_preview.info_youtube.views_average_videos'),
            value: this.data_user.metrics.views_avg?.value_video ? getFormat(this.data_user.metrics.views_avg.value_video) : 'N/A'
          },
          {
            label: this.$t('search_preview.info_youtube.views_average_shorts'),
            value: this.data_user.metrics.views_avg?.value_short ? getFormat(this.data_user.metrics.views_avg.value_short) : 'N/A'
          }
        ]
      }
    },
    cpe_prices() {
      if (this.network === 'instagram') {
        return this.data_user.features.blogger_prices?.data?.cpe ? this.data_user.features.blogger_prices.data.cpe.toFixed(2) : 'N/A';
      } else {
        return this.data_user.features.blogger_prices?.data?.cpm ? this.data_user.features.blogger_prices.data.cpm.toFixed(2) : 'N/A';
      }
    },
    mentions_yt() {
      return this.data_user.features.brand_mentions;
    },
    more_info() {
      if (this.network === 'instagram') {
        return [
          this.$t('search.interestAudience'),
          this.$t('search_preview.qualityAudience'),
          this.$t('instagram.adPerformance'),
          this.$t('search_preview.similarInfluencers'),
          this.$t('preview.audience_age_gender'),
          this.$t('search_preview.analysisHash'),
          this.$t('search_preview.analysisContent'),
          `...${this.$t('search_preview.more20')}`
        ]
      } else {
        return [
          this.$t('search_preview.info_youtube.audience_countries'),
          this.$t('search_preview.info_youtube.age_gender_audience'),
          this.$t('search_preview.info_youtube.audience_languages'),
          this.$t('search_preview.info_youtube.brand_safety_analysis'),
          this.$t('search_preview.info_youtube.estimated_cost_per_thousand'),
          this.$t('search_preview.info_youtube.estimated_integration_price'),
          this.$t('search_preview.info_youtube.content_analysis'),
          this.$t('search_preview.info_youtube.brand_mentions'),
          this.$t('search_preview.info_youtube.more_metrics')
        ]
      }
    }
  },
  methods: {
    redirectPost(post, social_type) {
      if (social_type === 'ig') {
        window.open(`https://www.instagram.com/p/${post}`, '_blank');
      } else {
        window.open(`https://www.youtube.com/watch?v=${post}`, '_blank');
      }
    },
    // Get images content on the top of report
    getImagesContent() {
      if (this.data_user.features.last_media?.data && Object.keys(this.data_user.features.last_media.data).length > 0) {
        const array_images = []
        for (const image_objet in this.data_user.features.last_media.data) {
          array_images.push({
            preview_url: this.network === 'instagram' ? this.data_user.features.last_media.data[image_objet].basic.preview_url :  this.data_user.features.last_media.data[image_objet].thumbnail,
            likes_count: this.network === 'instagram' ? this.data_user.features.last_media.data[image_objet].metrics.likes_count :  this.data_user.features.last_media.data[image_objet].metrics.likes_count.value,
            code: this.network === 'instagram' ? this.data_user.features.last_media.data[image_objet].basic.code : this.data_user.features.last_media.data[image_objet].id,
            network: this.network === 'instagram' ? this.data_user.features.last_media.data[image_objet].basic.social_type : this.data_user.features.last_media.data[image_objet].social_type
          })
        }
        return array_images.slice(0, 5);
      }
      return []
    },
    // Get the case of mentions for IG and YT
    mentionsCase(post_frequency) {
      const ranges = [
        { min: 22, traduction: 'mentions_text_mark.frequency' },
        { min: 15, traduction: 'mentions_text_mark.optimal_above' },
        { min: 7, traduction: 'mentions_text_mark.optimal' },
        { min: 5, traduction: 'mentions_text_mark.below_optimal' },
        { min: 0, traduction: 'mentions_text_mark.not_frequency' }
      ];

      for (const range of ranges) {
        if (post_frequency >= range.min) {
          return range.traduction;
        }
      }
    },
    
  }
};
</script>
<style scoped lang="scss">
.mr-05 {
  margin-right: 0.3em;
}
.mb-05 {
  margin-bottom: 0.5em;
}
.grid-container {
  display: grid;
  cursor:pointer;
  // background-color: red;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .grid-item {
    .container-icon-heart {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 45%;
      background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      display: flex;
      align-items: end;
      p {
        margin-left: 1em;
        color: white;
        font-family: 'avenir-medium';
        overflow: hidden;
      }
    }
    background-color: white;
    position: relative;
    .img-cover {
      width: 100%;
      height: 135.5px;
      object-fit: cover;
    }
    &:nth-child(1) {
      grid-column-start:1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      
      .img-cover {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 281px;
      }
    }
    &:nth-child(3) {
      .img-cover {
        border-top-right-radius: 6px;
      }
    }
    &:nth-child(5) {
      .img-cover {
        border-bottom-right-radius: 6px;
      }
    }
  }
}
.f-12 {
  font-size: 12px;
}
.grid-analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .title-result {
    font-size: 18px;
    width: 100%;
    display: block;
  }
}
.badge-nivel {
  font-family: 'avenir-medium' !important;
  font-size: 14px;
  margin-left: 0.5em;
  line-height: 1.3;
  font-weight: 500;
}
.icon-coin {
  width: 20px;
  height: 20px;
}
.icon-arrows-prices {
  display: block;
  flex-shrink: 0;
  color: #687385;
  background-color: #F5F8FA;
  border-radius: 100%;
  margin-right: 0.5em;
  margin-top: 0.2em;
}
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #E2E8F0;
  .grid-inner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
    .inner-box {
      border: 1px solid #E2E8F0;
      border-radius: 6px;
      box-shadow: 0 1px 4px #0b14260a;
      padding: 16px;
    }
  }
  .outline-secondary-custom-analysis {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    padding: 10px;
  }
  .grid-categories {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;

    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(70px, 1fr)); 
    }
    .img-grid-categorie {
      width: 24px;
      height: 24px;
    }
    .icon-b {
      width: 24px;
      height: 24px;
    }
  }
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;

  }
}
.toggle-button-post-history {
  background-color: #F5F8FA;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #E2E8F0;

  button {
    background: none !important;
    color: #687385 !important;
    box-shadow: none !important;
    padding: 9px !important;
    border: 1px solid transparent !important;
  }
  .active-button {
    background-color: white !important;
    border: 1px solid #E2E8F0 !important;
    color: #404452
  }
}
</style>
<style>

</style>